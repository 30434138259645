<template>
  <div>
    <h3>1 Innledning</h3>
    <p>
      I Norge forvaltes sjøvandrende laksefisk på bestandsnivå. Estimering av
      gytebestandsstørrelse er en sentral parameter for norsk lakseforvaltning,
      og danner grunnlaget for årlige vurderinger omkring høstingspotensial og
      vernebehov. Vitenskapelig råd for lakseforvaltning gjennomfører en årlig
      gjennomgang av vassdragene og gir råd om beskatning i de ulike vassdrag
      til Miljødirektoratet. Antallet av laks som hvert år kommer inn til kysten
      av Norge og opp i våre vassdrag, er mer enn halvert siden 1980-tallet.
      Antallet laks som gyter i elvene er likevel flere nå enn på 1980-talllet,
      selv om det høstbare overskuddet er redusert. At antallet gytefisk har økt
      selv om færre laks kommer opp i våre elver, beror på at innskrenkinger i
      fisketider og reduksjon av fiske i elv og sjø har kompensert for
      tilbakegangen (Anonym 2019). Bestandssituasjonen hos sjøørret har også
      vist en negativ trend over store deler av landet. Påvirkninger fra
      akvakulturnæringen står for de største negative påvirkningen på sjøørret
      bestandene, men også faktorer som blant annet landbruk, vannkraft og
      urbanisering fører i stor grad til reduksjon i leveområder og egnet
      habitat for sjøørretbestandene (Anonym 2019b. Bergan &amp; Solem 2018).
      Data fra bestandsovervåkning av laks og sjøørret i elver bidrar i stor
      grad bidra til kunnskap om norske laks- og sjøørretbestander, og er
      vesentlig for å kunne opprettholde en kunnskapsbasert forvaltning av de
      ulike bestandene.
    </p>
    <p>
      Estimat av gytebestandsstørrelser foregår på flere måter, deriblant
      drivtelling, lysfiske og videoovervåking.
    </p>
    <h3>2 Metode</h3>
    <p>
      I all hovedsak gjennomføres overvåkingen av gytebestandstørrelser av laks
      og sjøørret ved drivtellinger i de ulike vassdragene. I mindre vassdrag
      der drivtelling ikke vil gi gode resultater gjennomføres det tellinger ved
      hjelp av lys på kveldstid (lysfiske). I noen vassdrag gjennomføres det
      også videoregistreringer eller tellinger i trapper. Alle data fra de ulike
      tellemetodene er implementert i de årlige rapportene fra prosjektet.
    </p>
    <p>
      I forbindelse med de praktiske undersøkelsene vil hver elv deles inn i
      hensiktsmessige soner. Disse sonene er satt ut fra den enkelte
      institusjons behov for oppløsning på sine data, og for å kunne visualisere
      fordelingen av fisk i elvestrengen. I denne rapporten er det imidlertid
      ikke gitt en fullstendig oversikt over alle soner som inngår i de ulike
      vassdragene. Det er heller lagt vekt på å visualisere hvilke strekninger
      som er undersøkt i hvert vassdrag. I de ulike institusjoners arkiv finnes
      imidlertid finnes en mer detaljert oversikt, der vassdragene er delt opp i
      soner. Det er dermed mulig å beskrive romlig fordeling av gytefisk og
      dermed rogndeponering i ulike deler av elvestrengen, men dette er ikke en
      del av oppdraget gitt av Miljødirektoratet.
    </p>
    <p>
      All fisk som observeres i forbindelse med bestandsovervåkingen
      klassifiseres etter størrelse i tråd med norsk standard (Anonym 2015). For
      laks benyttes kategoriene smålaks (&lt; 3 kg), mellomlaks (3-7 kg) og
      storlaks (> 7 kg). Disse størrelseskategoriene samsvarer som oftest med
      sjøalder hos laks (1, 2 og 3 sjøvintre). Sjøørret deles i gruppene &lt; 1
      kg (umodne/modne), 1-3 kg, 3-5 kg og > 5 kg. Sjørøye deles inn i
      størrelseskategoriene mindre enn eller større enn 0,5 kg. Det kreves
      erfaring med undervannsobservasjoner for presise registreringer av art,
      kjønn og størrelse av fisk som i stor grad kan opptre i mindre eller
      større grupper. Under feltarbeid er det derfor avgjørende at det blir
      benyttet personell fra fagmiljøer som har tilstrekkelig erfaring med
      fisketellinger i elver, for å kunne gi et best mulig bestandsestimat.
    </p>

    <h4>2.1 Drivtellinger</h4>
    <p>
      Drivtelling er en mye brukt og viktig metode for å fremskaffe kunnskap om
      bestandsstørrelse for laksefisk i elver. Presisjon på gytefisktellinger
      vil variere ut fra observasjonsforhold, mannskapets erfaring (Orell mfl.
      2011) og vassdragets utforming (Orell &amp; Erkinaro 2007). En absolutt
      forutsetning for undervannsobservasjoner av fisk er at siktforholdene er
      tilfredsstillende (Gardiner 1984). Erfaringer med telling av gytefisk i
      elver der antall oppvandrende fisk er kjent fra fiskefeller eller
      videotelling, tilsier at en normalt ser 80 % eller mer under gode forhold
      (Skoglund mfl. 2014). Generelt antas det imidlertid at en vil få en større
      underestimering av bestandene i større vassdrag med mange dype områder og
      stort vannvolum (Skoglund mfl. 2014). De fleste gytefisktellinger i Norge
      har blitt gjennomført i mindre og mellomstore vassdrag. Imidlertid finnes
      det noen unntak som Saltdalselva, Beiarelva, Ranaelva, Røssåga
      (Kanstad-Hanssen &amp; Lamberg 2013), Vefsna (Holthe mfl. 2019a), Gaula,
      Orkla (Lamberg 2018), Surna (Johnsen mfl. 2011) og Driva (Bremset mfl.
      2012). Det er imidlertid ikke kjent hvor stor andel av gytefisken som har
      blitt observert i disse store vassdragene.
    </p>
    <h5>2.1.1 Beskrivelse av metode</h5>
    <p>
      Drivtellinger i elver med det formål å kartlegge gytebestander vil utføres
      ved at tellerne svømmer/driver nedover elva iført tørr- eller våtdrakter
      og snorkelutstyr (Vollset mfl. 2014). Stans i tellingene gjøres ved
      naturlige stoppunkter, som grunne strømnakker eller stilleflytende partier
      der det ikke står fisk. Tellerne får da muligheten til å notere
      observasjoner av fisk og diskutere observasjoner seg imellom. For å ha
      tilfredsstillende oversikt må tellerne holde blikket så langt fram som
      sikten tillater det, og pendle med hode fra side til side for å søke over
      en så stor sektor som mulig. For å unngå dobbeltregistreringer er det
      viktig å kun telle fisk som passeres, og ikke fisk som svømmer foran
      telleren nedover elva. I større vassdrag er det alltid flere tellere i
      elva samtidig, og det er derfor viktig at drivtellerne i størst mulig grad
      svømmer på linje i en mest mulig rett vinkel på elvestrømmen. Ved stopp
      kan nærliggende tellere samkjøre data for å forsøke å sikre at det ikke er
      gjennomført dobbelttellinger. Følgebåt er også benyttet i større
      laksevassdrag som Vefsna (Holthe mfl. 2019a), Driva (Bremset mfl. 2012),
      Surna (Ugedal mfl. 2014) og Eira (Jensen mfl. 2014). Erfaringene fra disse
      vassdragene er at følgebåt med lokalkjent mannskap har en betydelig
      sikkerhetsmessig gevinst (Bremset mfl. 2012), samt at man også kan få høy
      oppløselighet på data ved at observasjonene stedfestes med GPS. Høy
      oppløselighet på data er en fordel dersom man ønsker å analysere romlig
      fordeling av eggdeponering eller fordeling av gytefisk i elvene (Ugedal
      mfl. 2014, Holthe mfl. 2019a).
    </p>
    <h5>2.1.2 Presisjon på estimering av antall</h5>
    <p>
      Presisjonen for estimering av mengde laks ved hjelp av drivtelling er
      undersøkt i flere merkeforsøk. I slike forsøk merkes et kjent antall fisk
      med ulike merker og gjensynsraten bestemmes under drivtellingen. Mulige
      feilkilder ved merke/gjensynforsøk er at fisken blir mer synlig på grunn
      av merket, at den står delvis i skjul slik at merket ikke er synlig, at
      merket er for lite til å være godt synlig eller at fisken er merket kun på
      én side. Observasjonssannsynligheten kan dermed både over- og
      underestimeres ved merke/gjensynforsøk.
    </p>
    <p>
      For å gjennomføre bestandsestimat ble drivtelling med tre dykkere benyttet
      i Altaelva i perioden 2009-2011 ved å merke laks under høstfiske med godt
      synlige ytre merker (Peterson disc tags og radiomerker) (Ugedal mfl. 2010,
      2011, 2015). Resultatene fra disse undersøkelsene viste at mindre enn en
      tredjedel av den merkede laksen ble observert under drivtelling og
      antallet laks som ble observert i det samme området varierte betydelig
      mellom påfølgende drivtellinger. Den lave gjensynsraten kan skyldes dårlig
      sikt og at Altaelva er en stor og til dels en uoversiktlig elv, og at
      antallet drivtellere var for lavt i henhold til det som anbefales i Norsk
      Standard for elver at denne størrelsen.
    </p>
    <p>
      Forsøk med merking-gjensyn i en liten og klar sideelv i Tanavassdraget
      (Orell mfl. 2011) tyder på at presisjonen på drivtellinger kan være
      betydelig større enn det som ble funnet i Altaelva. I denne sideelva ble
      65-72 % og 81-82 % av radiomerket og videotelt laks observert av
      henholdsvis uerfarent og erfarent personell. Presisjonen varierte også
      mellom elveavsnitt. I kulper var nøyaktigheten 75-100 %, mens 43-82 % ble
      observert i strykområdene. Orell mfl. (2011) konkluderte med at
      drivtellinger er en god metode for å telle laks i mindre elver med gode
      siktforhold.
    </p>
    <p>
      Presisjon på drivtelling har også blitt undersøkt i Lakselva i Finnmark i
      2014 og 2016, der henholdsvis 71 og 43 % av radiomerket laks ble observert
      (Havn mfl. 2014; Uglem mfl. 2017). Fiskene i Lakselva var merket med
      synlige merker på begge sider, og vannføringen var lavere og
      siktforholdene var bedre i 2014 enn i 2016. Registreringene ble utført av
      to drivtellere og presisjonen ville trolig vært bedre dersom flere
      drivtellere hadde blitt brukt. Det har også blitt utført en metodetest i
      Skibotnelva i Troms der 22 av 26 (85 %) radiomerkede sjøørret og sjørøye
      ble observert under drivtelling to og tre dager etter merking (Kanstad
      Hanssen 2010). Nøyaktigheten ved drivtelling er videre undersøkt i Røssåga
      i 2009 ved merking av 30 fisk med «Floy-merker» på den ene siden av fisken
      (Lamberg mfl. 2010). Her ble 13 (43 %) av de merkede fiskene observert av
      seks drivtellere. En medvirkende årsak til den lave gjensynsraten i både
      Skibotnelva og Røssåga oppgis å være at fiskene bare var merket på én side
      (Lamberg mfl. 2010). Merkinggjensynvalidering av drivtelling tyder dermed
      på at andelen av fisk som observeres kan variere, samt at nøyaktigheten er
      bedre i mindre og klare elver enn i større elver.
    </p>
    <p>
      En annen metode for å evaluere nøyaktighet er å sammenligne
      drivtellingstall med notkast i samme høl. På denne måten unngår man
      problematikk knyttet til oppdagelsessjanse av merker. Et slikt studie er
      blitt gjennomført i en rekke vassdrag på Vestlandet (Mahlum mfl. 2019).
      Dette studiet hadde fokus på å validere i hvor stor grad dykkere klarer å
      identifisere rømt oppdrettslaks, noe som viste seg å ha en relativ god
      presisjon. I tillegg viser også studiet at det er meget god sammenheng
      mellom antall fisk observert i hølen og antall laks fanget i notfiske med
      strandnot/garn og garn i samme høl (Figur 1). Studiet ble gjennomført av
      erfarne dykkere fra NORCE, mens notfiske og skjellprøve takning ble
      gjennomført av Havforskningsinstituttet (HI) i relativt klare små og
      mellomstore elver.
    </p>
    <p>
      Drivtellinger er mye brukt for å estimere bestandsstørrelse i elver i
      andre land. I New Zealand er for eksempel oppdagelsessannsynligheten for
      storvokst ørret vist å variere mellom 21 og 77 % i elver med klart vann
      (Palmer &amp; Graybill 1986; Barker 1988; Young &amp; Hayes 2001). I to
      NINA Rapport 1849 12 kanadiske vassdrag fant Northcote &amp; Wilkie (1963)
      et godt samsvar mellom resultatene fra visuell fisketelling og påfølgende
      bruk av rotenon. Tilsvarende fant Dibble (1991) i et vassdrag i Arkansas i
      USA en klar sammenheng mellom relativ forekomst av fiskearter under
      fisketellinger og det som senere ble funnet under en påfølgende
      rotenonbehandling.
    </p>
    <h4>2.2 Lysfiske</h4>
    <p>
      Lysfiske egner seg godt til bestandsovervåking i mindre, grunne elver med
      lav vannføring, og vil bli benyttet i de vassdragene hvor forholdene
      ligger til rette for dette. Lysfiske er benyttet for å registrere mengde
      gytefisk i blant annet øvre deler av Surna (Johnsen mfl. 2011, Johnsen
      mfl. 2012b), Bævra (Johnsen mfl. 2012a), Vigda, Skjenaldelva,
      Snilldalselva og Børsaelva (Solem mfl. 2016; 2019), og Søa og Åelva
      (Holthe mfl. 2016; 2019b).
    </p>
    <h5>2.2.1 Beskrivelse av metode</h5>
    <p>
      Lysfiske foregår etter mørkets frambrudd ved at minimum tre personer vader
      oppover elvestrengen og søker systematisk etter gytefisk ved hjelp av
      hodelykter og håndholdte, lyssterke lykter. En av tellerne har ansvar for
      å notere ned alle registreringer av gytefisk. Observert gytefisk blir
      lettest paralysert ved å konsentrere lys mot fiskens hode. I noen
      tilfeller vil det være aktuelt å fange flest mulig av observerte gytefisk,
      mens i andre tilfeller vil bare et utvalg av observerte gytefisk bli
      fanget for prøvetaking. Ved registrering uten fanging av fisk vil det bare
      bli notert ned gytefisk som blir passert av tellerrekka. Et større eller
      mindre utvalg gytefisk blir fanget i store håver. I forbindelse med
      prøvetaking oppbevares fiskene i håv eller bærebag, mens det blir
      gjennomført artsbestemmelse, kjønnsbestemmelse, lengdemåling og
      skjellprøvetaking. Etter prøvetaking blir all antatt villaks og sjøørret
      gjenutsatt i elva på samme sted som de ble fanget. En kan ved lystelling
      få meget høy oppløselig på hvor i vassdraget fisken befinner seg ved
      stedfesting ved hjelp av GPS. Metoden er nærmere beskrevet i Johnsen mfl.
      (2011) og Næsje mfl. (2013).
    </p>
    <h5>2.2.2 Presisjon på estimering av antall</h5>
    <p>
      Det foreligger ingen estimater hvor stor andel av gytefisken man klarer å
      observere på en gitt elvestrekning under lysfiske. Basert på erfaring er
      dyp, elvas bredde og vertikal sikt ned i vannet de tre viktigste
      parameterne som påvirker observasjonssannsynlighet gitt at lysfisket
      legges så tett inntil fiskens gyteperiode som mulig. I så måte vil
      nøyaktigheten kunne variere med elvas topografi. Gitt at forannevnte
      kriterier optimaliseres antas nøyaktigheten på lysfisket å være svært god.
      Lysfiske er velegnet i mindre vassdrag eller vassdragsavsnitt som kan
      vades over hele bredden og der drivtellinger ikke lar seg gjennomføre på
      en god måte.
    </p>
    <h4>2.3 Videoovervåkning</h4>
    <p>
      Videoovervåkning kan gi mye informasjon om laksebestandene i et vassdrag.
      Tilsvarende de andre metodene kan videotelling under egnede forhold gi
      informasjon av antall fisk som går opp i elvene. En av fordelene med video
      er at en kan estimere oppgang av fisk forbi tellepunktet før eventuell
      fangst, samt tidspunkt for oppvandring. En annen fordel med
      videoovervåkning er at hver fisk kan studeres på stillbilder, og en har
      derfor mere tid til å vurdere art, kjønn, størrelse og eventuelt
      oppdrettskarakterer på fiskene en har bilde av, forutsatt at
      siktforholdene og bildekvaliteten er tilstrekkelig god. Gjennomgang av
      video fra slik overvåkning og er ofte ressurskrevende, både med tanke på
      tidsbruk og kostnad, da det er en forutsetning at erfarent personell
      analyserer videomaterialet.
    </p>
    <h5>2.3.1 Beskrivelse av metode</h5>
    <p>
      Prinsippet for videoovervåking av oppvandrende laksefisk baseres på at
      fisk som passerer et punkt filmes enten gjennom et kontinuerlig opptak
      eller av en opptakssekvens som utløses av en sensor (mekanisk eller
      optisk). Valg av type overvåkingssystem vil derfor måtte tilpasses de
      ulike overvåkingslokalitetene, og kan grovt sett deles i 1) overvåking i
      fisketrapper og 2) overvåking av hele elvetverrsnitt.
    </p>
    <p>
      Det benyttes kamera som leverer SD-video (standard PAL oppløsning, 720x576
      pixler) som gir en reell oppløsning på over 550 TV-linjer. Kameraene har
      et synsfelt under vann på ca. 70 grader. For å sikre tilfredsstillende
      opptak når det er mørkt, benyttes kunstig lys (monokromatisk lys NINA
      Rapport 1849 14 eller IR). Videosignalene fra kameraene lagres på en
      harddisk-videoopptaker i time-lapse modus. Systemet er oppsatt med doble
      opptaksenheter for å sikre opptakene dersom en videoopptaker svikter.
      Bilderaten som benyttes under kontinuerlige opptak varierer mellom to til
      fire bilder per sekund. I fisketrapper brukes i tillegg en sensor til å
      utløse en videosekvens med bilderate på 50 bilder per sekund når en fisk
      passerer.
    </p>
    <p>
      I fisketrapper benyttes som regel ett eller to kamera som er koblet til en
      sensor/utløser i en kalv som fisken må passere gjennom, og kameraet står
      normalt om lag 70 cm fra fisken. I åpne tverrsnitt benyttes det systemer
      med flere kameraer (fire, åtte eller 12 kameraer) avhengig av
      tverrsnittets bredde og siktforholdene i elva. Avstanden mellom
      videokamera i åpne tverrsnitt varierer fra 1,2 til 2,5 meter, og tilpasses
      slik at ett kamera ser det neste kameraet i rekken (Svenning mfl. 2015).
    </p>
    <h5>2.3.2 Presisjon på estimering av antall</h5>
    <p>
      Videoovervåkning er en mye brukt metode til overvåking av fisk i elver,
      men metoden er i liten grad validert. Orell mfl. (2011) observerte
      imidlertid i et videosystem nederst i elva samtlige av 18 radiomerkede
      fisk som vandret ut av en liten og klar sideelv i Tanavassdraget.
      Ytterligere valideringstester med bruk av radiomerket fisk er så vidt oss
      kjent ikke publisert, men det antas er metoden er nøyaktig under gode
      forhold med god sikt og tilstrekkelig lys, og om et tilstrekkelig antall
      kamera benyttes. Det er behov for ytterligere validering av metoden,
      spesielt i tilfeller hvor kamera brukes til å telle et tverrsnitt av
      elvebunnen, samt i forhold til hvordan sikt og vannføring kan bidra til
      liknende utfordringer som for drivtelling og om fisk kan vandre opp og ned
      forbi tverrsnittet flere ganger.
    </p>
    <h4>2.4 Rømt oppdrettsfisk</h4>
    <p>
      Forekomst av rømt oppdrettsfisk vil også bli rapportert for elvene som
      inngår i overvåkningen. Rømt oppdrettslaks vil bli forsøkt skilt fra
      villaks ut fra morfologiske karakterer som kroppsfasong, pigmentering,
      finneslitasje og andre karakterer som er typiske for rømt oppdrettsfisk
      under drivtellinger, lysfiske og ved videoovervåkning. I mange tilfeller
      vil det likevel ikke være mulig å identifisere oppdrettslaks basert på
      utseende, spesielt for laks som har rømt tidlig i oppdrettssyklusen.
      Flergangsgytere har også ofte skader som kan forveksles med skader hos
      oppdrettslaks. Rømt oppdrettslaks har erfaringsmessig en annen atferd enn
      villaks, og som ofte bidrar til at oppdrettslaks skiller seg ut når den
      opptrer i større grupper med villaks. For eksempel opptrer oppdrettslaks
      mer urolig, og vil i mindre grad søke skjul enn villaks når den påtreffes
      i elven. Under drivtellinger får en imidlertid ikke alltid sett enkeltfisk
      lenge nok til å avgjøre om det er villaks eller oppdrettslaks. Ved
      usikkerhet om fisken er oppdrettsfisk eller villfisk, defineres den som
      villfisk. Andelen rømt oppdrettslaks som fremkommer ved gytefisktellingene
      vil derfor i mange tilfeller være underestimert i forhold til det faktiske
      innslaget av rømt oppdrettslaks i elva. Erfaringsmessig vil en sjelden
      feilbestemme villaks som rømt oppdrettslaks (Skoglund mfl. 2014).
    </p>
    <p>
      Det er gjennomført en metodetest for å validere presisjonen i å
      identifisere oppdrettslaks under drivtelling (Mahlum mfl. 2019, også
      beskrevet over). Denne viser at relativt god overenstemmelse mellom andel
      oppdrettslaks observert i notkast og drivtelling (Figur 2). Dette studiet
      ble derimot gjennomført av erfarne dykkere i middels til gode
      observasjonsforhold, og hvordan denne presisjonen varierer med erfaring og
      observasjonsforhold er derfor uvisst. I tillegg ble det i dette studiet
      rapportert data fra uttak av oppdrettsfisk ved bruk av harpun i en rekke
      vassdrag. Kun én av 182 individer identifiserte som oppdrettslaks ble feil
      klassifisert (dvs. én villaks ble klassifisert som oppdrettslaks). Dette
      viser at det generelt sett er lite sannsynlig å overestimere antall
      oppdrettslaks basert på feilklassifisering.
    </p>
    Et mindre, tilsvarende studie ble tidligere utført i tre elver i
    Troms/Finnmark, der all oppdrettslaks ble korrekt kategorisert i to elver,
    men presisjonen var lavere i den tredje elva (Svenning mfl. 2015).

    <p>
      Det er også ukjent hvordan presisjonen på beregninger av innslag av rømt
      oppdrettsfisk varierer mellom ulike metoder. En av fordelene med lysfiske
      er at en i mange tilfeller kan komme tettere på fisken og kan observere
      den i lengre tid, samt at en kan ta skjellprøver for analyser av
      vekstmønster og genetisk opphav. Identifisering fra video er avhengige av
      bildekvalitet mm, men har en fordel ved at fiskene kan sjekkes om igjen,
      og at bestemmelsene dermed er repeterbare. Variasjonen i nøyaktighet
      mellom de ulike metodene understreker av det er behov for å fokusere på
      harmonisering, metodeoptimalisering og kvalitetssikring.
    </p>
    <p>
      Forekomst av rømt oppdrettslaks i elver registrert i drivtellinger, samt
      ved andre registreringsmetoder (skjellprøver fra sportsfiske, høstfiske og
      stamfiske) blir fulgt opp i et eget nasjonalt overvåkingsprogram (Glover
      mfl. 2019).
    </p>
    <h4>2.5 Tidspunkt for gjennomføring</h4>
    <p>
      Sannsynligheten for å få en god gytefisktelling vil avhenge av tidspunktet
      for gjennomføring. Dette synes å være spesielt viktig i vassdrag hvor fisk
      oppholder seg i innsjøer før og etter gyting. Erfaringsmessig er det
      viktig å gjennomføre tellingen etter innsiget av anadromfisk og før
      hovedgytingen er ferdig, slik at fisken ikke har sluppet seg ned i sjøen
      eller i innsjøer etter gyting. Ettersom sjøørreten vanligvis gyter
      tidligere enn laksen, kan det i noen tilfeller være vanskelig å få til
      drivtellinger som fanger opp begge artene. Dette betyr i praksis at det i
      noen vassdrag er vanskelig å gjennomføre en god gytefisktelling både for
      laks og sjøørret på samme tidspunkt. Erfaringsmessig bruker ofte
      sjøørreten å være på gyteområdene i en kortere periode enn laksen, og det
      er derfor ofte et kortere tidsvindu for å fange opp gytende sjøørret på
      tellinger. Tidspunktet for gjennomføring av tellinger er i stor grad styrt
      av vannførings- og siktforhold i elvene, og ofte er det kun noen få dager
      tilgjengelig med egnete forhold for å gjennomføre tellinger om høsten. I
      enkelte vassdrag er det også ulike forhold som pålegg om minstevannføring
      eller breavsmelting som setter begrensinger for hvor tidlig tellinger kan
      utføres. I noen tilfeller vil det derfor være nødvendig å prioritere
      tidspunkt for tellinger for å sikre best mulig data for en av artene, noe
      som i praksis tilsier at viktige sjøørretvassdrag bør prioriteres tidlig i
      sesongen.
    </p>
    <h3>2.6 Kvalitetssikring</h3>
    <h4>2.6.1 Generelt om kvalitetssikring</h4>
    <p>
      Kvaliteten på data fra både drivtellinger, lysfiske og videoovervåking vil
      variere som følge av en rekke forhold. For eksempel vil tellingene være
      sterkt avhengig av observasjonsforhold, som ofte vil variere betydelig
      mellom vassdrag og over tid, samtidig som det ofte vil være lettere å
      observere alle/en større del av gytebestanden i små elver med få
      skjuleplasser enn i store vassdrag med store høler og loner. For hver av
      tellingene er det foretatt en kvalitetsvurdering hvor kvaliteten blir
      vurdert på en skala fra 1-4, der 1 angir best kvalitet og 4 angir dårligst
      kvalitet. Kvalitetsvurderingene beskriver både fysiske forhold som sikt og
      observasjonsforhold og vassdragets kompleksitet, når tellingene er utført
      i forhold til gytetidspunkt til henholdsvis laks og sjøaure i vassdraget,
      samt en totalvurdering om hvor godt dataene beskriver bestandsstørrelse.
      Følgende kvalitetselementer blir vurdert:
    </p>
    <ul>
      <li>Sikt og observasjonsforhold:</li>
      <ul>
        <li>1: svært gode,</li>
        <li>2: gode,</li>
        <li>3: middels,</li>
        <li>4: dårlig</li>
      </ul>
      <p></p>
      <li>
        Utfordringer med å identifisere fisk som følge av store vannvolum (dype
        høler/loner) eller store fisketettheter:
      </li>
      <ul>
        <li>1: lite utfordrende,</li>
        <li>2: mindre utfordrende,</li>
        <li>3: stedvis utfordrende,</li>
        <li>4: svært utfordrende</li>
      </ul>
      <p></p>
      <li>Laks -Utførelse i forhold til gytetidspunkt:</li>
      <ul>
        <li>
          1: innenfor gyteperioden og mesteparten av fisken er på gyteområdene,
        </li>
        <li>
          2: innenfor gyteperioden eller tidspunkt da mesteparten av bestanden
          er på elva, men enkelte er utgytt eller står i oppholdshøler,
        </li>
        <li>
          3: noe før eller etter, deler av bestanden er utgytt eller og kan ha
          forlatt elva eller ikke kommet enda,
        </li>
        <li>
          4: tidlig/sent, sannsynlig at en betydelig andel av bestanden ikke er
          på elva
        </li>
      </ul>
      <p></p>
      <li>
        Laks- Total kvalitetsvurdering for hvor godt egnet dataene er for å
        beskrive bestandsstørrelse:
      </li>
      <ul>
        <li>1-svært god,</li>
        <li>2-god,</li>
        <li>3-middels,</li>
        <li>4-dårlig</li>
      </ul>
      <p></p>
      <li>Sjøørret -Utførelse i forhold til gytetidspunkt:</li>
      <ul>
        <li>
          1: innenfor gyteperioden og mesteparten av fisken er på gyteområdene,
        </li>
        <li>
          2: innenfor gyteperioden eller tidspunkt da mesteparten av bestanden
          er på elva, men enkelte er utgytt eller står i oppholdshøler,
        </li>
        <li>
          3: noe før eller etter, deler av bestanden er utgytt eller og kan
          forlatt elva eller ikke kommet enda,
        </li>
        <li>
          4: tidlig/sent, sannsynlig at en betydelig andel av bestanden ikke er
          på elva
        </li>
      </ul>
      <p></p>
      <li>
        Sjøørret - total kvalitetsvurdering for hvor godt egnet dataene er for å
        beskrive bestandsstørrelse:
      </li>
      <ul>
        <li>1-svært god,</li>
        <li>2-god,</li>
        <li>3-middels,</li>
      </ul>
    </ul>

    <p>
      I tillegg gis det en totalvurdering av antatt observasjonssannsynlighet
      for laks og sjøørret i prosent for hvert vassdrag. Samlet gir
      kvalitetsvurderingen et grunnlag for å vurdere usikkerheter i tellingene,
      og hvilke faktorer som kan bidra til å påvirke presisjonene i
      datagrunnlaget.
    </p>
    <p></p>
    <p></p>
  </div>
</template>

<script>
export default {
  name: "MetodikkTab"
};
</script>

<style scoped>
/* Add any styles specific to this component here */
</style>
