<template>
  <!-- <Layout> -->
  <!-- <img src="@assets/images/logo.png" alt="Logo" /> -->
  <b-container
    fluid
    style="background-color: #D5D5E3;  padding-left: 0px; padding-right: 0px;"
  >
    <div class="menu-content">
      <div style="padding-left: 15px; padding-right: 15px;">
        <div
          class="accordion"
          role="tablist"
          v-for="(county, index) in countiesWithWatercourses"
          :key="index"
        >
          <b-card no-body class="mb-1">
            <b-card-header
              header-tag="header"
              class="p-1"
              role="tab"
              style="color: red"
            >
              <b-button
                variant="light"
                block
                v-b-toggle="'accordion-' + index"
                >{{ county.countyName }}</b-button
              >
            </b-card-header>
            <b-collapse
              :id="getCountyId(index)"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <ul>
                  <li
                    v-for="(watercourse, index) in county.watercourses"
                    :key="index"
                  >
                    <a
                      href="#"
                      v-on:click="
                        getLastReport(watercourse.code),
                          getSelectedWatercourse(watercourse.code),
                          getWatercourseForMap(watercourse.code),
                          getReportList(watercourse.code)
                      "
                      >{{ watercourse.code }} -
                      {{ titleCase(watercourse.name) }}</a
                    >
                  </li>
                </ul>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <div align="center" style="padding:10px; ">
          <div style="padding:5px">
            <a href="https://www.nina.no"
              ><img :src="images.logo_nina" alt="NINA" width="120"
            /></a>
          </div>
          <div style="padding:5px">
            <a href="https://www.norceresearch.no"
              ><img :src="images.logo_norce" alt="NORCE" width="120"
            /></a>
          </div>
          <div style="padding:5px">
            <a href="https://www.facebook.com/Naturovervaking/"
              ><img
                :src="images.logo_sno"
                alt="Skandinavisk Naturovervåkning AS"
                width="120"
            /></a>
          </div>
          <div style="padding:5px">
            <a href="https://www.radgivende-biologer.no/"
              ><img
                :src="images.logo_rb"
                alt="Rådgivende biologer AS"
                width="120"
            /></a>
          </div>
          <div style="padding:5px">
            <a href="https://www.vetinst.no/"
              ><img :src="images.logo_vi" alt="Veterinærinstituttet" width="120"
            /></a>
          </div>
          <div style="padding:5px">
            <a href="https://ninord.no/"
              ><img
                :src="images.logo_ninord"
                alt="Naturtjenester i Nord"
                width="120"
            /></a>
          </div>
        </div>
      </div>
    </div>

    <div class="main-content">
      <div class>
        <BTabs v-model="tabIndex" content-class="mt-3" name="tabber">
          <b-tab to="/kart" title="Kart" active @click="[mapReadyMainMap()]">
            <!-- mapReadyMainMap()-->
            <div class="row">
              <div class="col-md-4 mb-4">Søk etter vassdrag:</div>
              <div class="col-md-4 mb-4">
                <b-form-input
                  v-model="searchWatercourse"
                  list="my-list-id"
                ></b-form-input>
                <datalist id="my-list-id">
                  <!-- <option>Manual Option</option> -->
                  <option
                    v-for="(watercourse, index) in watercoursesForMap"
                    :key="watercourse.code + index"
                  >
                    {{ watercourse.code }} - {{ titleCase(watercourse.name) }}
                  </option>
                </datalist>
              </div>
              <div class="col-md-4 mb-4">
                <b-button @click="searchForWatercourse"
                  >Gå til vassdrag</b-button
                >
              </div>
            </div>

            <div class="container border">
              <l-map
                ref="myMap"
                :zoom="zoom"
                :center="center"
                style="height:800px"
                @ready="doSomethingOnReady"
              >
                <l-control-layers position="topright"></l-control-layers>
                <l-tile-layer
                  v-for="tileProvider in tileProviders"
                  :key="tileProvider.key"
                  :name="tileProvider.name"
                  :visible="tileProvider.visible"
                  :url="tileProvider.url"
                  :attribution="tileProvider.attribution"
                  layer-type="base"
                />
                <l-wms-tile-layer
                  v-for="layer in layers"
                  :key="layer.name"
                  :base-url="baseUrl"
                  :layers="layer.layers"
                  :visible="layer.visible"
                  :name="layer.name"
                  :version="layer.version"
                  layer-type="base"
                />

                <l-circle-marker
                  v-for="w in watercoursesForMap"
                  :key="w.id"
                  :lat-lng="{ lat: w.latitude, lng: w.longitude }"
                  @click="
                    getLastReport(w.code),
                      getSelectedWatercourse(w.code),
                      getWatercourseForMap(w.code),
                      getReportList(w.code)
                  "
                >
                  />
                  <l-tooltip
                    :content="w.code + ' - ' + w.name"
                  />></l-circle-marker
                >
              </l-map>
            </div>
          </b-tab>
          <b-tab title="Vassdrag" @click="[mapReady()]">
            <div v-if="selectedReport" class="container p-4">
              <div>
                <h3>
                  {{ selectedWatercourse.code }} -
                  {{ titleCase(selectedWatercourse.name) }}
                </h3>
              </div>

              <div>
                <select
                  class="form-control"
                  @change="getSelectedReport($event)"
                >
                  <!-- <option selected="selected" value="null" disabled>-</option> -->
                  <option
                    v-for="(report, index) in reportList"
                    :value="report.id"
                    :key="report.code + index"
                    >{{ yearOnly(report.date) }} - {{ report.method }} -{{
                      report.excecutiveInstitution
                    }}
                  </option>
                </select>
              </div>

              <div v-if="selectedReport" class="container p-3">
                <h4>
                  Generelt om elva og tellingen - {{ selectedReport.year }}
                </h4>

                <div>
                  <div class="row pb-2">
                    <div class="col bold">Undersøkelsestidspunkt:</div>
                    <div class="w-50 col-auto">
                      <span> {{ formattedDate(selectedReport.date) }}</span>
                    </div>
                  </div>

                  <div class="row pb-2">
                    <div class="col bold">Utførende institusjon:</div>
                    <div class="w-50 col-auto">
                      <span>{{ selectedReport.excecutiveInstitution }}</span>
                    </div>
                  </div>

                  <div class="row pb-2">
                    <div class="col bold">Metode:</div>
                    <div class="w-50 col-auto">
                      <span>{{ selectedReport.method }}</span>
                    </div>
                  </div>
                  <div v-if="selectedReport">
                    <div class="row pb-2">
                      <div class="col bold">Anadrom strekning:</div>
                      <div class="w-50 col-auto">
                        <span> {{ selectedWatercourse.anadromLength }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="row pb-2">
                    <div class="col bold">
                      Innsjøer i anadrom del av vassdraget:
                    </div>
                    <div class="w-50 col-auto">
                      <span>{{ selectedReport.lakeAnadrom }}</span>
                    </div>
                  </div>

                  <div class="row pb-2">
                    <div class="col bold">
                      Prosent av anadrom strekning undersøkt:
                    </div>
                    <div class="w-50 col-auto">
                      <span>{{ selectedReport.percentAnadrom }}</span>
                    </div>
                  </div>

                  <div class="row pb-2">
                    <div class="col bold">Elvestrekninger som inngår:</div>
                    <div class="w-50 col-auto">
                      <span v-if="selectedReport.riverStretch">
                        {{ selectedReport.riverStretch }}
                      </span>
                      <span v-else> Ikke utført</span>
                    </div>
                  </div>

                  <div class="row pb-2">
                    <div class="col bold">Vanntemperatur (C):</div>
                    <div class="w-50 col-auto">
                      <span>{{ selectedReport.waterTemperature }}</span>
                    </div>
                  </div>

                  <div class="row pb-2">
                    <div class="col bold">Vannføring (m3/s):</div>
                    <div class="w-50 col-auto">
                      <span>{{ selectedReport.waterFlow }}</span>
                    </div>
                  </div>

                  <div class="row pb-2">
                    <div class="col bold">Effektiv sikt (meter):</div>
                    <div class="w-50 col-auto">
                      <span>{{ selectedReport.visibility }}</span>
                    </div>
                  </div>

                  <div class="row pb-2">
                    <div class="col bold">Kort feltrapport:</div>
                  </div>
                  <div class="row">
                    <div class="col-auto">
                      <span>{{ selectedReport.fieldReportText }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Slutt generelt om tellingen -->
              <!-- Start kvalitetsparametre -->
              <div class="container p-3">
                <h4>Kvalitetsparametre</h4>
                <div class="row pb-2">
                  <div class="col bold">
                    Sikt og observasjonsforhold:
                  </div>
                  <div class="w-50 col-auto">
                    <span>{{ selectedReport.observationConditionsName }}</span>
                  </div>
                </div>

                <div class="row pb-2">
                  <div class="col bold">
                    Utfordringer med å identifisere fisk som følge av store
                    vannvolum (dype høler/loner) eller store fisketettheter:
                  </div>
                  <div class="w-50 col-auto">
                    <span>{{ selectedReport.challengesName }} </span>
                  </div>
                </div>

                <div class="row pb-2">
                  <div class="col bold">
                    Kvalitetsvurdering av hvor stor andel av gytebestanden som
                    trolig ble observert (i prosent) Laks:
                  </div>
                  <div class="w-50 col-auto">
                    <span>{{
                      selectedReport.qP_SalmonQualityAssessmentPercent
                    }}</span>
                  </div>
                </div>

                <div class="row pb-2">
                  <div class="col bold">
                    Kvalitetsvurdering av hvor stor andel av gytebestanden som
                    trolig ble observert (i prosent) SØ:
                  </div>
                  <div class="w-50 col-auto">
                    <span
                      >{{
                        selectedReport.qP_BrownTroutQualityAssessmentPercent
                      }}
                    </span>
                  </div>
                </div>

                <div class="row pb-2">
                  <div class="col bold">
                    Utførelse i forhold til gytetidspunkt Laks:
                  </div>
                  <div class="w-50 col-auto">
                    <span>{{ selectedReport.salmonExecutionTimeName }}</span>
                  </div>
                </div>

                <div class="row pb-2">
                  <div class="col bold">
                    Utførelse i forhold til gytetidspunkt SØ:
                  </div>
                  <div class="w-50 col-auto">
                    <span>{{
                      selectedReport.brownTroutExecutionTimeName
                    }}</span>
                  </div>
                </div>

                <div class="row pb-2">
                  <div class="col bold">
                    Utførelse i forhold til gytetidspunkt Pukkelaks:
                  </div>
                  <div class="w-50 col-auto">
                    <span>{{
                      selectedReport.pinkSalmonExecutionTimeName
                    }}</span>
                  </div>
                </div>

                <div class="row pb-2">
                  <div class="col bold">
                    Laks - Total kvalitetsvurdering for hvor godt egnet dataene
                    er for å beskrive bestandsstørrelse:
                  </div>
                  <div class="w-50 col-auto">
                    <span>{{
                      selectedReport.salmonQualityAssessmentName
                    }}</span>
                  </div>
                </div>

                <div class="row pb-2">
                  <div class="col bold">
                    Sjøørret - Total kvalitetsvurdering for hvor godt egnet
                    dataene er for å beskrive bestandsstørrelse:
                  </div>
                  <div class="w-50 col-auto">
                    <span
                      >{{ selectedReport.brownTroutQualityAssessmentName }}
                    </span>
                  </div>
                </div>

                <div class="row">
                  <div class="col bold">
                    Begrunnelse for kvalitetsvurdering:
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-auto">
                    <span
                      >{{ selectedReport.qP_QualityAssessmentRationale }}
                    </span>
                  </div>
                </div>
              </div>

              <!-- Slutt kvalitetsparametre -->
              <!-- Start telledata -->
              <div class="container p-3">
                <h4>Telledata</h4>

                <table class="table table-hover table-striped">
                  <thead>
                    <tr class="bg-default">
                      <th colspan="5">LAKS</th>
                      <th colspan="6">SJØØRRET</th>
                      <th colspan="2">SJØRØYE</th>
                      <th colspan="2">PUKKELLAKS</th>
                    </tr>
                    <tr class="bg-default" style="font-size:12px">
                      <th scope="col"></th>
                      <th scope="col">Små (1-3 kg)</th>
                      <th scope="col">Mellom (3-7 kg)</th>
                      <th scope="col">Store (> 7 kg</th>
                      <th scope="col">Totalt</th>
                      <th scope="col">Umodne</th>
                      <th scope="col">&lt; 1 kg</th>
                      <th scope="col">1-3 kg</th>
                      <th scope="col">3-5 kg</th>
                      <th scope="col">&gt;5 kg</th>
                      <th scope="col">Totalt</th>
                      <th scope="col">&lt; 0,5 kg</th>
                      <th scope="col">&gt; 0,5 kg</th>
                      <th scope="col">Ikke gytt</th>
                      <th scope="col">Utgytt</th>
                    </tr>
                    <tr>
                      <td>Villfisk</td>
                      <td>
                        <!-- {{ selectedReport.cD_WildSalmonSmallFemale }} |
                    {{ selectedReport.cD_WildSalmonSmallMale }} |
                    {{ selectedReport.cD_WildSalmonSmallUnknown }} | -->
                        {{ wildSalmonSmallAll }}
                      </td>
                      <td>
                        <!-- {{ selectedReport.cD_WildSalmonMediumFemale }} |
                    {{ selectedReport.cD_WildSalmonMediumMale }} |
                    {{ selectedReport.cD_WildSalmonMediumUnknown }} | -->
                        {{ wildSalmonMediumAll }}
                      </td>
                      <td>
                        <!-- {{ selectedReport.cD_WildSalmonLargeFemale }} |
                    {{ selectedReport.cD_WildSalmonLargeMale }} |
                    {{ selectedReport.cD_WildSalmonLargeUnknown }} | -->
                        {{ wildSalmonLargeAll }}
                      </td>
                      <td>{{ sumWildSalmon }}</td>
                      <td>{{ selectedReport.cD_BrownTroutImmature }}</td>
                      <td>{{ selectedReport.cD_BrownTrout_0_1 }}</td>
                      <td>{{ selectedReport.cD_BrownTrout_1_3 }}</td>
                      <td>{{ selectedReport.cD_BrownTrout_3_5 }}</td>
                      <td>{{ selectedReport.cD_BrownTrout_5 }}</td>
                      <td>{{ sumBrownTrout }}</td>
                      <td>{{ selectedReport.cD_ArcticChar_0_05 }}</td>
                      <td>{{ selectedReport.cD_ArcticChar_05 }}</td>
                      <td>{{ selectedReport.cD_PinkSalmonPreSpawn }}</td>
                      <td>{{ selectedReport.cD_PinkSalmonPostSpawn }}</td>
                    </tr>
                    <tr>
                      <td>Oppdrett</td>
                      <td>{{ selectedReport.cD_CultivatedSalmonSmall }}</td>
                      <td>
                        <!-- {{ selectedReport.cD_CultivatedSalmonMediumFemale }} |
                    {{ selectedReport.cD_CultivatedSalmonMediumMale }} |
                    {{ selectedReport.cD_CultivatedSalmonMediumUnknown }} | -->
                        {{ cultivatedSalmonMediumAll }}
                      </td>
                      <td>
                        <!-- {{ selectedReport.cD_CultivatedSalmonLargeFemale }} |
                    {{ selectedReport.cD_CultivatedSalmonLargeMale }} |
                    {{ selectedReport.cD_CultivatedSalmonLargeUnknown }} | -->
                        {{ cultivatedSalmonLargeAll }}
                      </td>
                      <td>
                        {{ sumCultivated }}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td colspan="15"></td>
                    </tr>
                    <tr>
                      <td colspan="4">Observert kultivert laks (%)</td>
                      <td colspan="11">
                        {{ selectedReport.cD_CultivatedSalmonPercent }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="4">Observert kultivert laks (n)</td>
                      <td colspan="11">
                        {{ selectedReport.cD_CultivatedSalmon }}
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
              <!-- Slutt telledata -->
            </div>
            <div>
              <img
                v-if="!imageErrors[0]"
                :src="getSalmonMainImageUrl()"
                class="stats-image"
                @error="() => (imageErrors[0] = true)"
                @click="openModal(getSalmonMainImageUrl())"
              />
              <img
                v-if="!imageErrors[1]"
                :src="getSalmonMainImageUrl(true)"
                class="stats-image"
                @error="() => (imageErrors[1] = true)"
                @click="openModal(getSalmonMainImageUrl(true))"
              />
              <img
                v-if="!imageErrors[2]"
                :src="getSalmonVariantImageUrl()"
                class="stats-image"
                @error="() => (imageErrors[2] = true)"
                @click="openModal(getSalmonVariantImageUrl())"
              />

              <img
                v-if="!imageErrors[3]"
                :src="getTroutMainImageUrl()"
                class="stats-image"
                @error="() => (imageErrors[3] = true)"
                @click="openModal(getTroutMainImageUrl())"
              />
              <img
                v-if="!imageErrors[4]"
                :src="getTroutMainImageUrl(true)"
                class="stats-image"
                @error="() => (imageErrors[4] = true)"
                @click="openModal(getTroutMainImageUrl(true))"
              />
              <img
                v-if="!imageErrors[5]"
                :src="getTroutVariantImageUrl()"
                class="stats-image"
                @error="() => (imageErrors[5] = true)"
                @click="openModal(getTroutVariantImageUrl())"
              />

              <!-- Modal for displaying full-size images -->
              <b-modal
                id="image-modal"
                v-model="modalVisible"
                title=" "
                hide-footer
              >
                <img :src="currentImageUrl" class="modal-fullsize-image" />
              </b-modal>
            </div>

            <div class="container p-1">
              <div id="mapid" ref="mapElement" style="height:600px"></div>
            </div>
          </b-tab>

          <b-tab to="/metodikk" title="Metodikk">
            <MetodikkTab />
          </b-tab>
          <b-tab to="/om" title="Om Prosjektet">
            <p>
              <!-- Litt info om løsningen -->
              På oppdrag fra Miljødirektoratet ble det i 2019 opprettet ett
              nasjonalt program for overvåkning av gytebestander av laks og
              sjøørret. Tidligere har bestandsovervåkningen vært fordelt på
              flere institusjoner som hver for seg har rapportert sine
              resultater til Miljødirektoratet og Vitenskapelig råd for
              lakseforvaltning (VRL). Opprettelsen av et eget program for
              bestandsovervåkning gjør at resultater fra bestandsovervåkning fra
              de ulike deler av landet nå vil bli samlet i en rapport. Som et
              hovedmål for programmet skal det leveres kvalitetssikrede data som
              vurderer gytebestandsstørrelsen i de vassdragene som inngår i
              programmet. Prosjektgruppa skal også jobbe for å gjennomføre
              harmonisering og optimalisering av metoder for overvåkning av
              gytebestander av anadrom laksefisk i Norge.
            </p>
            <p>
              Skandinavisk Naturovervåkning AS, Ferskvannsbiologen AS,
              Rådgivende biologer AS, Veterinærinstituttet og Naturtjenester i
              Nord, sammen med NORCE og NINA utfører undersøkelsene i elvene som
              inngår i overvåkingsprogrammet. Samarbeidskonstellasjonen sikrer
              et faglig grunnlag for å vurdere bestandsstatus hos laks og
              sjøørret i ulike vassdrag i Norge. Deltagerne i prosjektet har
              siden tidlig på 1990-tallet jobbet med kartlegging av
              gytebestander og bestandsovervåking over hele landet, og har
              derfor bred erfaring med de metoder for fisketelling som er
              skissert i denne rapporten, og utvikling av disse.
            </p>
            <p>
              Alle de deltakende institusjoner har bidratt i rapporteringen av
              de innhentede resultater. I tillegg til de elver som
              Miljødirektoratet har bedt om overvåkning i, er det hentet inn
              data på gytebestandstørrelser fra flere vassdrag der de ulike
              institusjonene har gjennomført overvåkning. I vassdrag der
              Miljødirektoratet ikke har finansiert tellingene, er det etter
              beste evne forsøkt å synliggjøre finansieringskilder under hvert
              vassdrags delrapport. I denne rapporten presenteres de ulike
              metoder som blir benyttet under gytebestandsovervåkning, samt
              detaljerte resultater fra de vassdrag der det er gjennomført
              undersøkelser.
            </p>
            <p>
              For vurderinger av hvert vassdrag se
              <a
                href="https://www.vitenskapsradet.no/VurderingAvEnkeltbestander"
                >Vitenskapelig råd for lakseforvaltning</a
              >.
            </p>
            <!-- <p>
              Vi ønsker å takke Miljødirektoratet for oppdraget, og takker
              samtidig alle andre som har finansiert ulike prosjekter som har
              bidratt med data til denne rapporten. Vi ønsker også å takke alle
              som har deltatt i forbindelse med prosjektet.
            </p>
            <p>Trondheim, Juni 2020</p>
            <p>Espen Holthe, Helge Skoglund og Øyvind Solem</p> -->
            <div
              style="align:center; text-align:center; display: inline-block;width: 80%; padding: 10px;"
            >
              <div class="row">
                <div
                  class="col-sm mx-auto logo"
                  style="text-align:center; padding: 10px;"
                >
                  <a href="https://www.norceresearch.no"
                    ><img :src="images.logo_norce" alt="NORCE" width="120"
                  /></a>
                </div>
                <div class="col-sm mx-auto"></div>
                <div
                  class="col-sm mx-auto logo"
                  style="text-align:center; padding: 10px;"
                >
                  <a href="https://www.nina.no"
                    ><img
                      :src="images.logo_nina"
                      alt="NINA"
                      href="http://www.nina.no/"
                      width="120"
                  /></a>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-sm mx-auto"
                  style="text-align:center; padding: 10px;"
                >
                  <a href="https://www.radgivende-biologer.no/"
                    ><img
                      :src="images.logo_rb"
                      alt="Rådgivende biologer AS"
                      width="120"
                  /></a>
                </div>
                <div
                  class="col-sm mx-auto logo"
                  style="text-align:center; padding: 10px;"
                >
                  <a href="https://www.vetinst.no/"
                    ><img
                      :src="images.logo_vi"
                      alt="Veterinærinstituttet"
                      height="50"
                  /></a>
                </div>
                <div
                  class="col-sm mx-auto logo"
                  style="text-align:center; padding: 10px;"
                >
                  <a href="https://ninord.no/"
                    ><img
                      :src="images.logo_ninord"
                      alt="Naturtjenester i Nord"
                      width="120"
                  /></a>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-sm mx-auto"
                  style="text-align:center; padding: 10px;"
                >
                  <a href="https://www.facebook.com/Naturovervaking/"
                    ><img
                      :src="images.logo_sno"
                      alt="Skandinavisk Naturovervåkning AS"
                      width="120"
                  /></a>
                </div>
                <div class="col-sm mx-auto"></div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Registrering" disabled>
            <p>Endre telling</p>
            <div>
              <div class="row">
                <div class="col-md-6 mb-4">Fylke:</div>
                <div class="w-50">
                  <b-form-select
                    v-model="selectedCounty"
                    :options="counties"
                    @change="getWatercoursesByCounty"
                  ></b-form-select>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 mb-4">Vassdragsnummer og vassdrag:</div>
                <div class="w-50">
                  <b-form-select
                    v-model="selectedWatercourseCode"
                    :options="watercourses"
                    @change="
                      getLastReport(selectedWatercourseCode),
                        getSelectedWatercourse(selectedWatercourseCode),
                        getWatercourseForMap(w.code),
                        getReportList(w.code)
                    "
                  ></b-form-select>
                </div>
              </div>

              <div v-if="selectedReport">
                <BTabs content-class="mt-3">
                  <b-tab title="Generelt om elva og tellingen" active>
                    <!-- <p>I"m the first tab</p> -->

                    <!-- Start generelt om tellingen -->
                    <div class="container border p-4 test">
                      <h4>Generelt om elva og tellingen</h4>

                      <div class="row">
                        <div class="col-md-6 mb-4">Undersøkelsestidspunkt:</div>
                        <div class="w-50">
                          <b-form-datepicker id="dato"></b-form-datepicker>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">Utførende institusjon:</div>
                        <div class="w-50">
                          <b-form-input
                            v-model="selectedReport.excecutiveInstitution"
                            placeholder="Fritekst"
                          >
                          </b-form-input>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">Metode:</div>
                        <div class="w-50">
                          <b-form-input
                            v-model="selectedReport.method"
                            placeholder="Fritekst"
                          >
                          </b-form-input>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">
                          Innsjøer i anadrom del av vassdraget:
                        </div>
                        <div class="w-50">
                          <b-form-input
                            v-model="selectedReport.lakeAnadrom"
                            placeholder="Fritekst"
                          >
                          </b-form-input>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">
                          Prosent av anadrom strekning undersøkt:
                        </div>
                        <div class="w-50">
                          <b-form-input
                            v-model.number="selectedReport.percentAnadrom"
                            placeholder="Fritekst"
                          >
                          </b-form-input>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">
                          Elvestrekninger som inngår:
                        </div>
                        <div class="w-50">
                          <b-form-input
                            v-model="selectedReport.riverStretch"
                            placeholder="Fritekst"
                          >
                          </b-form-input>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">Vanntemperatur (C):</div>
                        <div class="w-50">
                          <b-form-input
                            v-model="selectedReport.waterTemperature"
                            placeholder="Fritekst"
                          >
                          </b-form-input>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">Vannføring (m3/s):</div>
                        <div class="w-50">
                          <b-form-input
                            v-model="selectedReport.waterFlow"
                            placeholder="Fritekst"
                          ></b-form-input>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">Effektiv sikt (meter):</div>
                        <div class="w-50">
                          <b-form-input
                            v-model.number="selectedReport.visibility"
                            placeholder="Fritekst"
                          ></b-form-input>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">Kort feltrapport:</div>
                        <div class="w-50">
                          <b-form-textarea
                            id="textarea"
                            v-model="selectedReport.fieldReportText"
                            placeholder="Skriv noe her..."
                            rows="4"
                            max-rows="10"
                          ></b-form-textarea>
                        </div>
                      </div>
                    </div>
                    <!-- Slutt generelt om tellingen -->
                  </b-tab>
                  <b-tab title="Kvalitetsparametre">
                    <!-- <p>I"m the second tab</p> -->
                    <!-- Start kvalitetsparametre -->
                    <div class="container p-4">
                      <h4>Kvalitetsparametre</h4>
                      <div class="row">
                        <div class="col-md-6 mb-4">
                          Sikt og observasjonsforhold:
                        </div>
                        <div class="w-50">
                          <b-form-select
                            v-model="selectedReport.observationConditionsId"
                            :options="siktOgObsforhold"
                          >
                          </b-form-select>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">
                          Utfordringer med å identifisere fisk som følge av
                          store vannvolum (dype høler/loner) eller store
                          fisketettheter:
                        </div>
                        <div class="w-50">
                          <b-form-select
                            v-model="selectedReport.challengesId"
                            :options="utfordringer"
                          >
                          </b-form-select>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">
                          Kvalitetsvurdering av hvor stor andel av gytebestanden
                          som trolig ble observert (i prosent) Laks:
                        </div>
                        <div class="w-50">
                          <b-form-input
                            v-model.number="
                              selectedReport.qP_SalmonQualityAssessmentPercent
                            "
                            type="number"
                            placeholder="Fritekst (tall)"
                          >
                          </b-form-input>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">
                          Kvalitetsvurdering av hvor stor andel av gytebestanden
                          som trolig ble observert (i prosent) SØ:
                        </div>
                        <div class="w-50">
                          <b-form-input
                            v-model.number="
                              selectedReport.qP_BrownTroutQualityAssessmentPercent
                            "
                            type="number"
                            placeholder="Fritekst (tall)"
                          >
                          </b-form-input>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">
                          Utførelse i forhold til gytetidspunkt Laks:
                        </div>
                        <div>
                          <b-form-select
                            v-model="selectedReport.salmonExecutionTimeId"
                            :options="utforelse"
                          >
                          </b-form-select>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">
                          Utførelse i forhold til gytetidspunkt SØ
                        </div>
                        <div>
                          <b-form-select
                            v-model="selectedReport.brownTroutExecutionTimeId"
                            :options="utforelse"
                          >
                          </b-form-select>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">
                          Laks - Total kvalitetsvurdering for hvor godt egnet
                          dataene er for å beskrive bestandsstørrelse:
                        </div>
                        <div class="w-50">
                          <b-form-select
                            v-model="selectedReport.salmonQualityAssessmentId"
                            :options="kvalitetsvudering"
                          >
                          </b-form-select>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">
                          Sjøørret - Total kvalitetsvurdering for hvor godt
                          egnet dataene er for å beskrive bestandsstørrelse:
                        </div>
                        <div class="w-50">
                          <b-form-select
                            v-model="
                              selectedReport.brownTroutQualityAssessmentId
                            "
                            :options="kvalitetsvudering"
                          >
                          </b-form-select>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-6 mb-4">
                          Begrunnelse for kvalitetsvudering:
                        </div>
                        <div class="w-50">
                          <b-form-textarea
                            id="textarea"
                            v-model="
                              selectedReport.qP_QualityAssessmentRationale
                            "
                            placeholder="Skriv noe her..."
                            rows="4"
                            max-rows="10"
                          >
                          </b-form-textarea>
                        </div>
                      </div>
                    </div>
                    <!-- Slutt kvalitetsparametre -->
                  </b-tab>
                  <b-tab title="Telledata">
                    <!-- <p>I"m the third tab</p> -->
                    <!-- Start telledata -->
                    <div class="container  p-4">
                      <h4>Telledata</h4>

                      <table class="table table-bordered">
                        <thead>
                          <tr class="bg-default">
                            <th colspan="6">LAKS</th>
                            <th colspan="6">SJØØRRET</th>
                            <th colspan="2">SJØRØYE</th>
                          </tr>
                          <tr class="bg-default" style="font-size:10px">
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col">Små (1-3 kg)</th>
                            <th scope="col">Mellom (3-7 kg)</th>
                            <th scope="col">Store (> 7 kg)</th>
                            <th scope="col">Totalt</th>
                            <th scope="col">Umodne</th>
                            <th scope="col">&lt; 1 kg</th>
                            <th scope="col">1-3 kg</th>
                            <th scope="col">3-5 kg</th>
                            <th scope="col">&gt;5 kg</th>
                            <th scope="col">Totalt</th>
                            <th scope="col">&lt; 0,5 kg</th>
                            <th scope="col">&gt; 0,5 kg</th>
                          </tr>
                          <tr>
                            <td>Villfisk</td>
                            <td></td>
                            <td>
                              <b-form-input
                                v-model.number="
                                  selectedReport.cD_WildSalmonSmallFemale
                                "
                                type="number"
                              ></b-form-input>
                              <b-form-input
                                v-model.number="
                                  selectedReport.cD_WildSalmonSmallMale
                                "
                                type="number"
                              ></b-form-input>
                              <b-form-input
                                v-model.number="
                                  selectedReport.cD_WildSalmonSmallUnknown
                                "
                                type="number"
                              ></b-form-input>
                              <!-- {{ selectedReport.cD_WildSalmonSmallFemale }} |
                    {{ selectedReport.cD_WildSalmonSmallMale }} |
                    {{ selectedReport.cD_WildSalmonSmallUnknown }} | -->
                              <!-- {{ selectedReport.cD_WildSalmonSmall }} -->
                            </td>
                            <td>
                              <b-form-input
                                v-model.number="
                                  selectedReport.cD_WildSalmonMediumFemale
                                "
                                type="number"
                              ></b-form-input>
                              <b-form-input
                                v-model.number="
                                  selectedReport.cD_WildSalmonMediumMale
                                "
                                type="number"
                              ></b-form-input>
                              <b-form-input
                                v-model.number="
                                  selectedReport.cD_WildSalmonMediumUnknown
                                "
                                type="number"
                              ></b-form-input>
                              <!-- {{ selectedReport.cD_WildSalmonMediumFemale }} |
                    {{ selectedReport.cD_WildSalmonMediumMale }} |
                    {{ selectedReport.cD_WildSalmonMediumUnknown }} | -->
                              <!-- {{ wildSalmonMediumAll }} -->
                            </td>
                            <td>
                              <b-form-input
                                v-model.number="
                                  selectedReport.cD_WildSalmonLargeFemale
                                "
                                type="number"
                              ></b-form-input>
                              <b-form-input
                                v-model.number="
                                  selectedReport.cD_WildSalmonLargeMale
                                "
                                type="number"
                              ></b-form-input>
                              <b-form-input
                                v-model.number="
                                  selectedReport.cD_WildSalmonLargeUnknown
                                "
                                type="number"
                              ></b-form-input>
                              <!-- {{ selectedReport.cD_WildSalmonLargeFemale }} |
                    {{ selectedReport.cD_WildSalmonLargeMale }} |
                    {{ selectedReport.cD_WildSalmonLargeUnknown }} | -->
                              <!-- {{ wildSalmonLargeAll }} -->
                            </td>
                            <td>
                              {{ sumWildSalmon }}
                            </td>
                            <td>
                              <b-form-input
                                v-model.number="
                                  selectedReport.cD_BrownTroutImmature
                                "
                                type="number"
                              ></b-form-input>
                            </td>
                            <td>
                              <b-form-input
                                v-model.number="
                                  selectedReport.cD_BrownTrout_0_1
                                "
                                type="number"
                              ></b-form-input>
                            </td>
                            <td>
                              <b-form-input
                                v-model.number="
                                  selectedReport.cD_BrownTrout_1_3
                                "
                                type="number"
                              ></b-form-input>
                            </td>
                            <td>
                              <b-form-input
                                v-model.number="
                                  selectedReport.cD_BrownTrout_3_5
                                "
                                type="number"
                              ></b-form-input>
                            </td>
                            <td>
                              <b-form-input
                                v-model.number="selectedReport.cD_BrownTrout_5"
                                type="number"
                              ></b-form-input>
                            </td>
                            <td>
                              {{ sumBrownTrout }}
                            </td>
                            <td>
                              <b-form-input
                                v-model.number="
                                  selectedReport.cD_ArcticChar_0_05
                                "
                                type="number"
                              ></b-form-input>
                            </td>
                            <td>
                              <b-form-input
                                v-model.number="selectedReport.cD_ArcticChar_05"
                                type="number"
                              ></b-form-input>
                            </td>
                          </tr>
                          <tr>
                            <td>Oppdrett</td>
                            <td></td>
                            <td>
                              <b-form-input
                                v-model.number="
                                  selectedReport.cD_CultivatedSalmonSmall
                                "
                                type="number"
                              ></b-form-input>
                              <!-- {{ selectedReport.cD_CultivatedSalmonSmall }} -->
                            </td>

                            <td>
                              <b-form-input
                                v-model.number="
                                  selectedReport.cD_CultivatedSalmonMediumFemale
                                "
                                type="number"
                              ></b-form-input>
                              <b-form-input
                                v-model.number="
                                  selectedReport.cD_CultivatedSalmonMediumMale
                                "
                                type="number"
                              ></b-form-input>
                              <b-form-input
                                v-model.number="
                                  selectedReport.cD_CultivatedSalmonMediumUnknown
                                "
                                type="number"
                              ></b-form-input>
                              <!-- {{ selectedReport.cD_CultivatedSalmonMediumFemale }} |
                    {{ selectedReport.cD_CultivatedSalmonMediumMale }} |
                    {{ selectedReport.cD_CultivatedSalmonMediumUnknown }} | -->
                              <!-- {{ cultivatedSalmonMediumAll }} -->
                            </td>
                            <td>
                              <b-form-input
                                v-model.number="
                                  selectedReport.cD_CultivatedSalmonLargeFemale
                                "
                                type="number"
                              ></b-form-input>
                              <b-form-input
                                v-model.number="
                                  selectedReport.cD_CultivatedSalmonLargeMale
                                "
                                type="number"
                              ></b-form-input>
                              <b-form-input
                                v-model.number="
                                  selectedReport.cD_CultivatedSalmonLargeUnknown
                                "
                                type="number"
                              ></b-form-input>
                              <!-- {{ selectedReport.cD_CultivatedSalmonLargeFemale }} |
                    {{ selectedReport.cD_CultivatedSalmonLargeMale }} |
                    {{ selectedReport.cD_CultivatedSalmonLargeUnknown }} | -->
                              <!-- {{ cultivatedSalmonLargeAll }} -->
                            </td>
                            <td>
                              {{ sumCultivated }}
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td colspan="13"></td>
                          </tr>
                          <tr>
                            <td colspan="4">Observert kultivert laks (%)</td>
                            <td colspan="9">
                              <b-form-input
                                v-model.number="
                                  selectedReport.cD_CultivatedSalmonPercent
                                "
                                type="number"
                                step=".01"
                              ></b-form-input>
                              <!-- {{ selectedReport.cD_CultivatedSalmonPercent }} -->
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">Observert kultivert laks (n)</td>
                            <td colspan="9">
                              <b-form-input
                                v-model.number="
                                  selectedReport.cD_CultivatedSalmon
                                "
                                type="number"
                              ></b-form-input>
                              <!-- {{ selectedReport.cD_CultivatedSalmon }} -->
                            </td>
                          </tr>
                        </thead>
                      </table>

                      <div class="row">
                        <div class="col-md-6 mb-4">
                          Rådata fra tellingen sonevis:
                        </div>
                        <div class="w-50">
                          <b-form-file
                            v-model="file"
                            :state="Boolean(file)"
                            placeholder="Velg en fil, eller slipp den her"
                            drop-placeholder="Drop file here..."
                          >
                          </b-form-file>
                          <div class="mt-3">
                            Valgt fil: {{ file ? file.name : "" }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Slutt telledata -->
                  </b-tab>
                  <b-tab title="Kart og soneinndeling">
                    <p>
                      Her kommer kart med soneinndeling, og tabell med sonene.
                    </p>
                    <div class="row">
                      <div class="col-md-6 mb-4">Opplasting av bilde:</div>
                      <div class="w-50">
                        <b-form-file
                          v-model="file"
                          :state="Boolean(file)"
                          placeholder="Velg en fil, eller slipp den her"
                          drop-placeholder="Drop file here..."
                        >
                        </b-form-file>
                      </div>
                    </div>
                  </b-tab>
                </BTabs>
                <div class="row p-4">
                  <b-button @click="saveReport()" variant="success"
                    >Lagre telling</b-button
                  >
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Import" disabled>
            <p><!-- Last opp rådata fra excel-dokument --></p>
            <div class="row">
              <div class="col-md-6 mb-4">
                Import fra Excel-dokument.
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mb-4">Last opp data fra Excel-dokument:</div>
              <div class="w-50">
                <b-form-file
                  v-model="file"
                  :state="Boolean(file)"
                  placeholder="Velg en fil, eller slipp den her"
                  drop-placeholder="Drop file here..."
                >
                </b-form-file>
              </div>
            </div>
          </b-tab>
        </BTabs>
      </div>
    </div>
    <div>
      <!-- Image and text -->
    </div>
  </b-container>
  <!-- </Layout> -->
</template>

<script>
import Axios from "axios";
import Vue from "vue";
// import Layout from 'router/layouts/main.vue'
import { BESTANDSOVERVAKNING_CONFIG } from "@/../config.js";
// import appConfig from "@src/app.config"
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet-tilelayer-wmts/src/leaflet-tilelayer-wmts";
import { Icon } from "leaflet";
// import { router } from "@/router/index.js";
import MetodikkTab from "./MetodikkTab.vue";

const moment = require("moment");

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

import {
  LMap,
  LTileLayer,
  LCircleMarker,
  LControlLayers,
  LTooltip,
  LWMSTileLayer
} from "vue2-leaflet";

import {
  BTabs,
  BTab,
  BFormInput,
  BFormSelect,
  BFormDatepicker,
  BFormTextarea,
  BFormFile,
  BButton
} from "bootstrap-vue";

import * as bulmaToast from "bulma-toast";

export default {
  page: {
    title: "Home",
    meta: [{ name: "description", content: "Laksetelling" }]
  },
  components: {
    BTabs: BTabs,
    "b-tab": BTab,
    "b-form-input": BFormInput,
    "b-form-select": BFormSelect,
    "b-form-datepicker": BFormDatepicker,
    "b-form-textarea": BFormTextarea,
    "b-form-file": BFormFile,
    "b-button": BButton,
    "l-map": LMap,
    "l-tile-layer": LTileLayer,
    "l-circle-marker": LCircleMarker,
    "l-control-layers": LControlLayers,
    "l-tooltip": LTooltip,
    "l-wms-tile-layer": LWMSTileLayer,
    MetodikkTab
  },
  data() {
    return {
      images: {
        logo_sno: require("../assets/SNO_logo (002).jpg"),
        logo_rb: require("../assets/RB_logo.png"),
        logo_vi: require("../assets/VI_logo.png"),
        logo_ninord: require("../assets/NINORDlogo_2 copy.png"),
        logo_norce: require("../assets/NORCE logo.png"),
        logo_nina: require("../assets/NINA_logo.png")
      },
      counties: null,
      countiesWithWatercourses: null,
      watercourses: null,
      watercoursesForMap: null,
      watercourseForMap: null,

      selectedCounty: null,
      selectedWatercourseCode: null,
      selectedWatercourse: null,
      selectedReport: null,
      reportList: null,
      selectedYear: 2020,
      tabIndex: 1,
      watercourseMap: {},

      menu: [
        {
          header: true,
          title: "Main Navigation",
          hiddenOnCollapse: true
        },
        {
          href: "/",
          title: "Dashboard",
          icon: "fa fa-user"
        },
        {
          href: "/charts",
          title: "Charts",
          icon: "fa fa-chart-area",
          child: [
            {
              href: "/charts/sublink",
              title: "Sub Link"
            }
          ]
        }
      ],

      readonly: "",

      center: [65.8369, 13.1934],
      zoom: 5,
      baseUrl: "https://cache.kartverket.no/v1/wmts/1.0.0/topo/?",

      tileProviders: [
        {
          name: "OpenStreetMap",
          visible: true,
          center: [62.6752, 8.5633],
          attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        },
        {
          name: "OpenTopoMap",
          visible: false,
          center: [62.6752, 8.5633],
          url: "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
          attribution:
            'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
        }
      ],

      layers: [
        {
          name: "Topo",
          visible: true,
          format: "image/png",
          layers: "topo",
          tilematrixSet: "webmercator",
          transparent: true,
          version: "1.0",
          attribution: "Kartverket"
        }
      ],

      circle: {
        center: [62.676407, 8.550287],
        radius: 10,
        color: "blue"
      },

      searchWatercourse: null,

      selectedUtovendeInstitusjon: null,
      selectedMetode: null,
      selectedSiktOgObsforhold: null,
      selectedUtfordringer: null,
      selectedUtforelseLaks: null,
      selectedUtforelseSO: null,
      selectedKvalitetsvurderingLaks: null,
      selectedKvalitetsvurderingSO: null,
      file: null,
      showImage: false,

      utovendeInstitusjon: [
        { value: null, text: "Velg utøvende institusjon" },
        { value: 0, text: "NINA" },
        { value: 1, text: "NORCE" },
        { value: 2, text: "Skandinavisk naturovervåkning" },
        { value: 3, text: "Rådgivende biologer" },
        { value: 4, text: "Ferskvannsbiologen" },
        { value: 5, text: "Veterinærinstituttet" },
        { value: 6, text: "Andre" }
      ],
      metode: [
        { value: null, text: "Velg metode" },
        { value: 1, text: "Drivtelling" },
        { value: 2, text: "Lysfiske" },
        { value: 3, text: "Drivtelling og lysfiske" },
        { value: 4, text: "Video" }
      ],
      siktOgObsforhold: [
        { value: null, text: "Velg" },
        { value: 1, text: "Svært gode" },
        { value: 2, text: "Gode" },
        { value: 3, text: "Middels" },
        { value: 4, text: "Dårlige" }
      ],
      utfordringer: [
        { value: null, text: "Velg" },
        { value: 1, text: "Lite utfordrende" },
        { value: 2, text: "Mindre utfordrende" },
        { value: 3, text: "Stedvis utfordrende" },
        { value: 4, text: "Svært utfordrende" }
      ],
      utforelse: [
        { value: null, text: "Velg" },
        {
          value: 1,
          text:
            "Innenfor gyteperioden og mesteparten av fisken er på gyteområdene"
        },
        {
          value: 2,
          text:
            "Innenfor gyteperioden eller tidspunkt da mesteparten av bestanden er på elva, men enkelte er utgytt eller står i oppholdshøler"
        },
        {
          value: 3,
          text:
            "Noe før eller etter, deler av bestanden er utgytt eller og kan forlatt elva eller ikke kommet enda"
        },
        {
          value: 4,
          text: "Tidlig/sent og mulig at del av bestanden ikke er på elva"
        }
      ],
      kvalitetsvudering: [
        { value: null, text: "Velg" },
        { value: 1, text: "Svært god" },
        { value: 2, text: "God" },
        { value: 3, text: "Middels" },
        { value: 4, text: "Dårlig" }
      ],
      mainImageUrl: null,
      salmonMainImageError: false,
      salmonMainVariantError: false,
      salmonVariantError: false,
      troutMainImageError: false,
      troutMainVariantError: false,
      troutVariantError: false,
      imageErrors: [false, false, false, false, false, false],
      modalVisible: false,
      currentImageUrl: "" // Initialize currentImageUrl
    };
  },

  computed: {
    wildSalmonSmallAll: function() {
      return (
        this.selectedReport.cD_WildSalmonSmallFemale +
        this.selectedReport.cD_WildSalmonSmallMale +
        this.selectedReport.cD_WildSalmonSmallUnknown
      );
    },
    wildSalmonMediumAll: function() {
      return (
        this.selectedReport.cD_WildSalmonMediumFemale +
        this.selectedReport.cD_WildSalmonMediumMale +
        this.selectedReport.cD_WildSalmonMediumUnknown
      );
    },
    wildSalmonLargeAll: function() {
      return (
        this.selectedReport.cD_WildSalmonLargeFemale +
        this.selectedReport.cD_WildSalmonLargeMale +
        this.selectedReport.cD_WildSalmonLargeUnknown
      );
    },
    cultivatedSalmonMediumAll: function() {
      return (
        this.selectedReport.cD_CultivatedSalmonMediumFemale +
        this.selectedReport.cD_CultivatedSalmonMediumMale +
        this.selectedReport.cD_CultivatedSalmonMediumUnknown
      );
    },
    cultivatedSalmonLargeAll: function() {
      return (
        this.selectedReport.cD_CultivatedSalmonLargeFemale +
        this.selectedReport.cD_CultivatedSalmonLargeMale +
        this.selectedReport.cD_CultivatedSalmonLargeUnknown
      );
    },
    sumWildSalmon: function() {
      return (
        this.selectedReport.cD_WildSalmonSmallFemale +
        this.selectedReport.cD_WildSalmonSmallMale +
        this.selectedReport.cD_WildSalmonSmallUnknown +
        this.selectedReport.cD_WildSalmonMediumFemale +
        this.selectedReport.cD_WildSalmonMediumMale +
        this.selectedReport.cD_WildSalmonMediumUnknown +
        this.selectedReport.cD_WildSalmonLargeFemale +
        this.selectedReport.cD_WildSalmonLargeMale +
        this.selectedReport.cD_WildSalmonLargeUnknown
      );
    },
    sumCultivated: function() {
      return (
        this.selectedReport.cD_CultivatedSalmonSmall +
        this.selectedReport.cD_CultivatedSalmonMediumFemale +
        this.selectedReport.cD_CultivatedSalmonMediumMale +
        this.selectedReport.cD_CultivatedSalmonMediumUnknown +
        this.selectedReport.cD_CultivatedSalmonLargeFemale +
        this.selectedReport.cD_CultivatedSalmonLargeMale +
        this.selectedReport.cD_CultivatedSalmonLargeUnknown
      );
    },
    sumBrownTrout: function() {
      return (
        this.selectedReport.cD_BrownTrout_0_1 +
        this.selectedReport.cD_BrownTrout_1_3 +
        this.selectedReport.cD_BrownTrout_3_5 +
        this.selectedReport.cD_BrownTrout_5
      );
    }
  },

  mounted() {
    const self = this;

    this.$nextTick(() => {});
    Axios.get(
      `${BESTANDSOVERVAKNING_CONFIG.API_URL}` + "/watercourse/countiesdd"
    ).then(response => (self.counties = response.data));
    Axios.get(
      `${BESTANDSOVERVAKNING_CONFIG.API_URL}` + "/watercourse/countiesw"
    ).then(response => (self.countiesWithWatercourses = response.data));

    Axios.get(
      `${BESTANDSOVERVAKNING_CONFIG.API_URL}` + "/watercourse/watercoursesdd"
    ).then(response => (self.watercourses = response.data));
    Axios.get(
      `${BESTANDSOVERVAKNING_CONFIG.API_URL}` +
        "/watercourse/watercoursesForMap"
    ).then(res => {
      self.watercoursesForMap = res.data;
      self.getMap();
    });

    this.watercourseMap = L.map(this.$refs["mapElement"]);
    this.watercourseMap.dragging.disable();
    this.watercourseMap.scrollWheelZoom.disable();

    this.watercourseMap.setView([62.67757925, 8.550245158], 8);

    var topo4 = new L.tileLayer.wmts(
      "https://cache.kartverket.no/v1/wmts/1.0.0/topo/?",
      {
        layer: "topo",
        tilematrixSet: "webmercator",
        maxZoom: 20,
        format: "image/png",
        style: "default",
        transparent: true,
        version: "1.0.0",
        attribution: "Kartverket"
      }
    );

    topo4.addTo(this.watercourseMap);

    this.markerLayerGroup = L.layerGroup().addTo(this.watercourseMap);

    // ---
    // this.getLastReportInitial("030.2Z");
    // this.getSelectedWatercourse("030.2Z");
    // this.getWatercourseForMap("030.2Z");
    // this.getReportList("030.2Z");

    if (this.$route.params.id != undefined) {
      this.getLastReport(this.$route.params.id);
      this.getSelectedWatercourse(this.$route.params.id);
      this.getWatercourseForMap(this.$route.params.id);
      this.getReportList(this.$route.params.id);
    }

    this.checkAndLoadImages();
  },

  methods: {
    // onTabClick(tab) {
    //   this.$router.push(tab.path);
    // },

    getSelectedReport(event) {
      var reportId = event.target.value;
      this.getReportById(reportId);
    },
    getCountyId(index) {
      return "accordion-" + index;
    },
    doSomethingOnReady() {
      this.map = this.$refs.myMap.mapObject;
    },

    getMap() {},

    getWatercoursesByCounty(countyId) {
      let self = this;
      var url =
        `${BESTANDSOVERVAKNING_CONFIG.API_URL}` +
        "/watercourse/watercourses/" +
        countyId;

      Axios.get(url).then(response => (self.watercourses = response.data));
    },

    getLastReport(watercourseCode) {
      const self = this;
      if (watercourseCode != undefined) {
        var url =
          `${BESTANDSOVERVAKNING_CONFIG.API_URL}` +
          "/watercourse/lastReport/" +
          watercourseCode;

        Axios.get(url).then(res => {
          self.selectedReport = res.data;
          self.tabIndex = 1;
          self.selectedCounty = res.data.watercourseCountyNumber;
          self.selectedWatercourseCode = watercourseCode;
          self.mapReady();
          // alert("Test:" + this.$route.params.id);
          if (self.$route.params.id !== watercourseCode) {
            self.$router.push({
              name: "Home",
              params: { id: watercourseCode }
            });
          }
        });
      }
    },

    getLastReportInitial(watercourseCode) {
      const self = this;
      if (watercourseCode != undefined) {
        var url =
          `${BESTANDSOVERVAKNING_CONFIG.API_URL}` +
          "/watercourse/lastReport/" +
          watercourseCode;

        Axios.get(url).then(res => {
          self.selectedReport = res.data;
          self.selectedCounty = res.data.watercourseCountyNumber;
          self.selectedWatercourseCode = watercourseCode;
          self.mapReady();
        });
      }
    },

    getReportById(id) {
      const self = this;
      var url =
        `${BESTANDSOVERVAKNING_CONFIG.API_URL}` + "/watercourse/report/" + id;

      Axios.get(url).then(res => {
        self.selectedReport = res.data;
        self.tabIndex = 1;
        self.selectedCounty = res.data.watercourseCountyNumber;
      });
    },

    getReportList(code) {
      let self = this;
      var url =
        `${BESTANDSOVERVAKNING_CONFIG.API_URL}` +
        "/watercourse/reports/" +
        code;

      Axios.get(url).then(
        response => ((self.reportList = response.data), self.mapReady())
      );
    },

    getWatercourseForMap(code) {
      let self = this;
      var url =
        `${BESTANDSOVERVAKNING_CONFIG.API_URL}` +
        "/watercourse/watercourseForMap/" +
        code;

      Axios.get(url).then(
        response => ((self.watercourseForMap = response.data), self.mapReady())
      );
    },

    searchForWatercourse() {
      let self = this;
      var watercourseCode = this.searchWatercourse.substring(
        0,
        self.searchWatercourse.indexOf(" ")
      );

      var watercourse = self.watercoursesForMap.filter(obj => {
        return obj.code === watercourseCode;
      });

      var code = watercourse[0].code;

      self.getLastReport(code);
      self.getSelectedWatercourse(code);
      self.getReportList(code);
      self.getWatercourseForMap(code);
    },

    getSelectedWatercourse(watercourseCode) {
      let self = this;
      if (watercourseCode != undefined) {
        var url =
          `${BESTANDSOVERVAKNING_CONFIG.API_URL}` +
          "/watercourse/watercourse/" +
          watercourseCode;

        Axios.get(url).then(response => {
          self.selectedWatercourse = response.data;
          self.mapReady();
          // self.$router.push({ path: "/vassdrag/" + watercourseCode });
        });
      }
    },

    saveReport(report) {
      report = this.selectedReport;
      var url = `${BESTANDSOVERVAKNING_CONFIG.API_URL}` + "/watercourse/save";

      Axios.post(url, report).then(response => {
        alert(JSON.stringify(response));
        bulmaToast.toast({ message: "Lagret!" });
      });
    },

    formattedDate: function(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    yearOnly: function(date) {
      return moment(date).format("YYYY");
    },

    mapReady() {
      var self = this;
      if (
        self.watercourseForMap != undefined &&
        self.watercourseForMap.latitude != undefined
      ) {
        self.markerLayerGroup.clearLayers();

        L.marker([
          self.watercourseForMap.latitude,
          self.watercourseForMap.longitude
        ]).addTo(self.markerLayerGroup);

        Vue.nextTick().then(function() {
          self.watercourseMap.invalidateSize(true);
          self.watercourseMap.flyTo(
            [self.watercourseForMap.latitude, self.watercourseForMap.longitude],
            12
          );
        });
      }
    },

    mapReadyMainMap() {
      var self = this;
      Vue.nextTick().then(function() {
        self.map.invalidateSize(true);
        self.map.flyTo([65.8369, 13.1934], 5);
      });
    },

    titleCase(str) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },

    // Function to check if an image exists
    imageExists(url) {
      return Axios.head(url)
        .then(response => response.status === 200)
        .catch(() => false);
    },

    async checkAndLoadImages() {
      const urls = [
        this.getSalmonMainImageUrl(),
        this.getSalmonMainImageUrl(true),
        this.getSalmonVariantImageUrl(),
        this.getTroutMainImageUrl(),
        this.getTroutMainImageUrl(true),
        this.getTroutVariantImageUrl()
      ];

      this.imageErrors = Array(urls.length).fill(false); // Reset errors before checking

      urls.forEach((url, index) => {
        this.imageExists(url).then(exists => {
          this.$set(this.imageErrors, index, !exists);
        });
      });
    },

    // Construct image URL with specific naming for fish types
    constructImageUrl(folder, code, variant = "") {
      // const baseUrl =  `${BESTANDSOVERVAKNING_CONFIG.BASE_URL}`; // Ensure there is a fallback if BASE_URL is not defined
      const baseUrl = process.env.BASE_URL || ""; // Ensure there is a fallback if BASE_URL is not defined
      const fishName = folder === "salmon" ? " Laks" : " Sjøørret"; // Adjust based on folder
      code = encodeURIComponent(code);
      variant = encodeURIComponent(variant);

      // Construct the URL string using encoded values and checking if baseUrl ends with a slash
      const myUrl = `${
        baseUrl.endsWith("/") ? baseUrl : baseUrl + "/"
      }stats/${folder}/${code}${variant}${fishName}.png`;

      // Log the constructed URL to the console for debugging
      // console.log(`Constructed URL: ${myUrl}`);

      // Return the constructed URL
      return myUrl;
    },

    getSalmonMainImageUrl(withVariant = false) {
      const code = this.$route.params.id;
      const variantSuffix = withVariant ? " (a)" : "";
      return this.constructImageUrl("salmon", code, variantSuffix);
    },
    getSalmonVariantImageUrl() {
      const code = this.$route.params.id;
      return this.constructImageUrl("salmon", code, " (b)");
    },
    getTroutMainImageUrl(withVariant = false) {
      const code = this.$route.params.id;
      const variantSuffix = withVariant ? " (a)" : "";
      return this.constructImageUrl("trout", code, variantSuffix);
    },
    getTroutVariantImageUrl() {
      const code = this.$route.params.id;
      return this.constructImageUrl("trout", code, " (b)");
    },

    // Determine correct image URL by checking availability
    async determineCorrectImageUrl(folder, code, variants) {
      for (let variant of variants) {
        const url = this.constructImageUrl(folder, code, variant);
        if (await this.imageExists(url)) {
          return url;
        }
      }
      return `${process.env.BASE_URL || ""}stats/default.png`; // Default image if none found
    },

    // Error handler for image loading
    imageLoadOnError(e, errorType) {
      const defaultImageUrl = `${process.env.BASE_URL || ""}stats/default.png`;
      e.target.src = defaultImageUrl;
      this[errorType] = true; // Set the error flag
    },

    openModal(imageUrl) {
      this.currentImageUrl = imageUrl;
      this.modalVisible = true;
    }
  },
  watch: {
    selectedWatercourseCode(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.checkAndLoadImages();
      }
    }
  }
  // watch: {
  //   $route: function(to) {
  //     let tab = this.$refs.tabs.tabs.find(tab => tab.to === to.path);
  //     if (tab) this.$refs.tabs.setActiveTab(tab);
  //   }
  // },
  // beforeRouteUpdate(to, from, next) {
  //   let tab = this.$refs.tabs.tabs.find(tab => tab.to === to.path);
  //   if (tab) this.$refs.tabs.setActiveTab(tab);
  //   next();
  // }
};
</script>

<style>
.menu-content {
  display: inline-block;
  width: 25%;
  height: 100vh;
  background-color: #d5d5e3;
  height: 100vh;
  overflow-y: scroll;
}

.main-content {
  float: right;
  width: 75%;
  background-color: white;
  padding: 15px;
  overflow-y: scroll;
  height: 100vh;
}

@media only screen and (max-width: 768px) {
  .menu-content {
    display: none;
    width: 0%;
  }

  .main-content {
    width: 100%;
  }
}
</style>
